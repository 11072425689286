import * as React from "react";

import ProfileCard from "../components/ProfileCard";

const IndexPage = () => {
  return (
    <>
      <ProfileCard />
    </>
  );
};

export default IndexPage;
